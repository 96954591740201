<div class="mat-mdc-form-field-wrapper form-group d-flex flex-column m-0" [class.mb-3]="control.errors && control.touched">
  <mat-form-field class="d-block" [appearance]="appearance" [class.readonly]="readonly">
    <mat-label class="text-middle font-14">
      {{ label | translate }}
    </mat-label>

    <mat-select
      [formControl]="multiple ? multipleControl : control"
      (selectionChange)="getSelectedValue($event)"
      (openedChange)="filterControl.setValue(null)"
      [compareWith]="compareWithFn"
      [multiple]="multiple"
    >
      @if (withSearch) {
        <input
          class="mat-mdc-select-search-input w-100 px-3"
          type="text"
          [formControl]="filterControl"
          [placeholder]="placeholder | translate"
        />
      }

      @if (isHaveGroupBy) {
        @for (group of filteredGroupsOptions; track group.name) {
          <mat-optgroup [label]="group.name | translate">
            @for (option of group.options; track option) {
              <mat-option [value]="option[bindValue]" [disabled]="option.disabled">
                {{ option | localizedName }}
              </mat-option>
            }
          </mat-optgroup>
        }
      } @else {
        <!-- <mat-option class="reset" [value]="null">--</mat-option> -->
        @if (multiple) {
          <mat-option
            class="all"
            [value]="'all'"
            (click)="$event.stopPropagation()"
            (onSelectionChange)="$event.source.selected ? toggleAllRows() : null"
          >
            {{ "BLOCK_DAYS.ALL" | translate }}
          </mat-option>
        }

        @for (option of filteredOptions; track option) {
          <mat-option
            [value]="option[bindValue]"
            [disabled]="option.disabled"
            (click)="$event.stopPropagation()"
            (onSelectionChange)="
              multiple ? ($event.source.selected ? selection.select(option[bindValue]!) : selection.deselect(option[bindValue]!)) : null
            "
          >
            {{ option | localizedName }}
          </mat-option>
        }
      }
    </mat-select>

    @if (control.errors) {
      <mat-error [class]="matErrorClass">
        <span class="font-12">
          {{ control.errors | validationHandler }}
        </span>
      </mat-error>
    }
  </mat-form-field>
</div>

<!-- Usage
  <app-dropdown
    label="label"
    formControlName="xyz"
    [options]="options"
    (selected)="getSelected($event)"
  >
  </app-dropdown>
-->
