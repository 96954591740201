import { toSignal } from "@angular/core/rxjs-interop";
import { finalize, map, of } from "rxjs";
import { inject, Injectable, Signal } from "@angular/core";

import { HttpService } from "../http/http.service";
import { AuthService } from "../auth/auth.service";
import { Checkbox, Dropdown, GetPagedBody, Lookup, ReservationVisitorCategoriesPrice, Role, Service } from "../../interfaces";
import {
  EmploymentType,
  EventServicesCodes,
  MembershipType,
  ParentServiceCode,
  ResidenceType,
  ServiceCodes,
  TourServicesCodes,
  Gender,
} from "../../enums";

import { Permissions } from "src/assets/config/config";
import { MuseumBuildingService, NationalitiesService } from "src/app/features";

@Injectable({
  providedIn: "root",
})
export class LookupsService extends HttpService {
  private _baseUrl = "lookups/";
  private authService = inject(AuthService);
  private nationalitiesService = inject(NationalitiesService);
  private museumBuildingService = inject(MuseumBuildingService);

  private set baseUrl(_baseUrl: string) {
    this._baseUrl = _baseUrl;
  }

  protected override get baseUrl(): string {
    return this._baseUrl;
  }

  get isAdminRoute() {
    const userToken = this.convertTokenJWT();
    if (!userToken) return false;

    return Permissions.ServicesManagement.includes(userToken.RoleCode);
  }

  membershipCardPlans: Signal<Dropdown[] | undefined> | undefined;

  getReservationTypes() {
    return this.get<Lookup[]>({ APIName: "get-reservation-types" });
  }

  getGenders() {
    return of([
      { id: Gender.male, nameEn: "Male", nameAr: "ذكر" },
      { id: Gender.female, nameEn: "Female", nameAr: "أنثى" },
    ] as Dropdown[]);
  }

  getReservationServices() {
    return this.get<Service[]>({ APIName: "get-reservation-services" }).pipe(
      map((services) => {
        services = this.setServiceRoute(services);

        return services.filter((service) => {
          // displaying all the services except the tourism companies service for the company user.
          if (
            service.code === TourServicesCodes.TourismCompanies &&
            ((this.authService.user && this.authService.isCompanyUser) ||
              Permissions.ServicesManagement.includes(this.authService.user?.RoleCode!))
          )
            return false;

          // displaying all the services except the friends of oman museum service for the non-admin users.
          if (
            Permissions.ServicesManagement.includes(this.authService.user?.RoleCode!) &&
            service.code === TourServicesCodes.FriendsOfOmanMuseum
          )
            return false;

          // displaying the official institutions service only for the official institution user.
          if (
            service.code !== TourServicesCodes.OfficialInstitutions &&
            this.authService.user &&
            this.authService.isOfficialInstitutionUser
          )
            return false;

          // displaying all the services except the official institutions service.
          if (
            service.code === TourServicesCodes.OfficialInstitutions &&
            this.authService.user &&
            !this.authService.isOfficialInstitutionUser &&
            Permissions.ServicesManagement.includes(this.authService.user?.RoleCode!)
          )
            return false;

          return true;
        });
      }),
    );
  }

  getReservationVisitorCategoriesPrice(
    reservationTypeCode: ParentServiceCode,
    reservationTicketTypeCode: ServiceCodes,
    museumBuildingId: number | null = null,
    tourTypeId: number | null = null,
  ) {
    this.baseUrl = "reservationprice/";

    const body = {
      reservationTypeCode,
      reservationTicketTypeCode,
      museumBuildingId,
      tourTypeId,
    };

    return this.post<ReservationVisitorCategoriesPrice[]>({
      APIName: `get`,
      body,
    }).pipe(finalize(() => (this.baseUrl = "lookups/")));
  }

  getMuseumBuildingsFreeEntryTicket() {
    const body: GetPagedBody<Record<string, string | number | boolean>> = {
      pageSize: 50,
      pageNumber: 1,
      orderByValue: [
        {
          colId: "id",
          sort: "asc",
        },
      ],
      filter: {
        allowFreeTicket: true,
      },
    };

    return this.museumBuildingService.getPaged(body).pipe(map((response) => response.data));
  }

  getMuseumBuildingsEntranceTicket() {
    const body: GetPagedBody<Record<string, string | number | boolean>> = {
      pageSize: 50,
      pageNumber: 1,
      orderByValue: [
        {
          colId: "id",
          sort: "asc",
        },
      ],
      filter: {
        allowPaidTicket: true,
      },
    };

    return this.museumBuildingService.getPaged(body).pipe(map((response) => response.data));
  }

  getMuseumBuildings() {
    const body: GetPagedBody<Record<string, string | number | boolean>> = {
      pageSize: 50,
      pageNumber: 1,
      orderByValue: [
        {
          colId: "id",
          sort: "asc",
        },
      ],
      filter: {},
    };

    return this.museumBuildingService.getPaged(body).pipe(map((response) => response.data));
  }

  getDeviceTypes(reservationTypeCode: ServiceCodes) {
    this.baseUrl = "lookups/";

    return this.get<(Lookup & { price: number })[]>({ APIName: `get-service-types/${reservationTypeCode}` });
  }

  getEventTypes() {
    return this.get<Service[]>({ APIName: "get-event-types" });
  }

  getRequestTypes() {
    return this.get<Lookup[]>({ APIName: "get-request-type" });
  }

  getEventCategories() {
    return this.get<Lookup[]>({ APIName: "get-event-categories" });
  }

  getEventNatures() {
    return this.get<Lookup[]>({ APIName: "get-event-natures" });
  }

  getEventAudience() {
    return this.get<Lookup[]>({ APIName: "get-audience-types" });
  }

  getAudioGuidanceTargetCategories() {
    return this.get<Lookup[]>({ APIName: "get-audio-guidance-target-categories" });
  }

  getRoles() {
    return this.get<Role[]>({ APIName: "get-roles" });
  }

  getReservationTicketTypes() {
    return this.get<Lookup[]>({ APIName: "get-reservation-ticket-types" });
  }

  getTicketTypes() {
    return this.get<Lookup[]>({ APIName: "get-ticket-types" });
  }

  getTargetGroups() {
    return this.get<any[]>({ APIName: "get-targeted-groups" });
  }

  getOrganizationTypes(parentCode: keyof typeof ParentServiceCode) {
    this.baseUrl = "lookups/";
    return this.get<Lookup[]>({ APIName: `get-organization-types/${parentCode}` });
  }

  getRequestForms() {
    return this.get<Lookup[]>({ APIName: "get-organization-types" });
  }

  getOrganizationLocations() {
    return this.get<Lookup[]>({ APIName: "get-organization-location" });
  }

  getServiceTypes() {
    return this.get<Lookup[]>({ APIName: "get-service-types" });
  }

  getHallTypes() {
    return this.get<Lookup[]>({ APIName: "get-hall-types" });
  }

  private setServiceRoute(services: Service[]) {
    return services.map((service) => {
      let serviceRoute = "";

      if (service.code === EventServicesCodes.ExhibitionsAndEvents) {
        serviceRoute = this.isAdminRoute ? `/admin/booking-services/exhibitions-and-events` : `/services/exhibitions-and-events`;
      } else {
        serviceRoute = this.isAdminRoute
          ? `/admin/booking-services/book/${service.parentCode}/${service.code}`
          : `/services/book/${service.parentCode}/${service.code}`;
      }

      return {
        ...service,
        serviceRoute,
      };
    });
  }

  getLanguages() {
    return this.get<Lookup[]>({ APIName: "get-languages" });
  }

  getEducationLevels(organizationId: number | string) {
    return this.get<Lookup[]>({ APIName: `get-educational-levels/${organizationId}` });
  }

  getDisabilityTypes() {
    return this.get<Lookup[]>({ APIName: "disability-types" });
  }

  getCafeItems() {
    return this.get<Lookup[]>({ APIName: "get-cafe-items" });
  }

  getUserCategories() {
    return this.get<Lookup[]>({ APIName: "get-user-categories" });
  }

  getTargetedCategories() {
    return this.get<Lookup[]>({ APIName: "get-targeted-categories" });
  }

  getUserTypes() {
    return this.get<Lookup[]>({ APIName: "get-user-type" });
  }

  getTourTypes() {
    return this.get<Lookup[]>({ APIName: "get-tour-types" });
  }

  getNationalities() {
    return this.nationalitiesService.getAll();
  }

  getCountries(params: { code?: string } = {}) {
    return this.get<Dropdown[]>({ APIName: "get-countries", params });
  }

  getGovernorates(params: { code?: string; id?: number } = {}) {
    return this.get<Dropdown[]>({ APIName: `get-governorates`, params });
  }

  getStates(params: { code?: string; id?: number } = {}) {
    return this.get<Dropdown[]>({ APIName: `get-states`, params });
  }

  assignMembershipCardPlans() {
    if (!this.membershipCardPlans) this.membershipCardPlans = toSignal(this.getMembershipCardPlans());
  }

  getMembershipCardPlans() {
    return this.get<Dropdown[]>({ APIName: "get-membership-card-plans" });
  }

  getCouponTypes() {
    return this.get<Lookup[]>({ APIName: "get-coupon-types" });
  }

  getAllServices() {
    return this.get<Lookup[]>({ APIName: "get-reservation-services" });
  }

  getTables() {
    return this.get<Lookup[]>({ APIName: "get-tables" });
  }

  getAuditTypes() {
    return this.get<Lookup[]>({ APIName: "get-audit-types" });
  }

  /**
   * Retrieves a list of membership types.
   *
   * @returns An observable emitting an array of membership types, each containing:
   * - `id`: The identifier of the membership type.
   * - `nameEn`: The English name of the membership type.
   * - `nameAr`: The Arabic name of the membership type.
   */
  getMembershipTypes() {
    return of([
      { id: MembershipType.Person, nameEn: "Person", nameAr: "شخص" },
      { id: MembershipType.Company, nameEn: "Company", nameAr: "مؤسسة" },
    ] as Checkbox[]);
  }

  /**
   * Retrieves a list of employment types.
   *
   * @returns An observable emitting an array of employment types, each containing:
   * - `id`: The identifier of the employment type.
   * - `nameEn`: The name of the employment type in English.
   * - `nameAr`: The name of the employment type in Arabic.
   */
  getEmploymentTypes() {
    return of([
      { id: EmploymentType.Employee, nameEn: "Employee", nameAr: "موظف" },
      { id: EmploymentType.Retired, nameEn: "Retired", nameAr: "متقاعد" },
      { id: EmploymentType.JobSeeker, nameEn: "JobSeeker", nameAr: "باحث عن عمل" },
      { id: EmploymentType.Business, nameEn: "Business", nameAr: "عمل" },
    ] as Lookup[]);
  }

  /**
   * Retrieves the list of residence types.
   *
   * @returns An observable emitting an array of residence types, each containing:
   * - `id`: The identifier of the residence type.
   * - `nameEn`: The English name of the residence type.
   * - `nameAr`: The Arabic name of the residence type.
   */
  getResidenceTypes() {
    return of([
      { id: ResidenceType.Citizen, nameEn: "Citizen", nameAr: "مواطن" },
      { id: ResidenceType.Resident, nameEn: "Resident", nameAr: "مقيم" },
    ] as Lookup[]);
  }
}
