import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class SurveyService extends HttpService {
  protected override get baseUrl(): string {
    return "survey/";
  }

  addSurvey(survey: any) {
    return this.post<any>({ APIName: "add", body: survey });
  }

  sendSurveyToUser(survey: {userIds: string[], formCode: string}) {
    return this.post<any>({ APIName: "send", body: survey });
  }

  submitSurvey(survey: any) {
    return this.post<any>({ APIName: "submit", body: survey });
  }

  getUserSurvey() {
    return this.get<any>({ APIName: "user-survey"});
  }

  getSubmittedSurvey() {
    return this.get<any>({ APIName: "user-submitted-survey"});
  }

  getSurveyStatistics(code: string) {
    return this.get<any>({ APIName: `statistics/${code}`});
  }


}
