import { Observable, map } from "rxjs";
import { inject, Injectable } from "@angular/core";

import { UserRole } from "../../enums";
import { HttpService } from "../http/http.service";

import { environment } from "src/environments/environment";

interface LoginResponse {
  token: string;
  tokenValidTo: string;
  userId: string;
  nameEn: string | null;
  nameAr: string | null;
}

enum DiscountType {
  Percentage = 1,
  FixedAmount = 2,
}

@Injectable({
  providedIn: "root",
})
export class AuthService extends HttpService {
  protected override get baseUrl(): string {
    return "accounts/";
  }

  get user() {
    return this.convertTokenJWT();
  }

  get userRole() {
    if (!this.user?.RoleCode) return null;
    return this.user?.RoleCode;
  }

  get discountType() {
    if (!this.user?.DiscountType) return 0;
    return +this.user?.DiscountType;
  }

  get discountValue() {
    if (!this.user?.DiscountValue) return 0;
    return +this.user?.DiscountValue;
  }

  get isPercentageDiscount() {
    return this.discountType === DiscountType.Percentage;
  }

  get isFixedAmountDiscount() {
    return this.discountType === DiscountType.FixedAmount;
  }

  get isAdmin() {
    if (!this.user) return false;

    return this.user?.RoleCode === UserRole.ADMIN;
  }

  get isGuest() {
    if (!this.user) return false;

    return this.user?.RoleCode === UserRole.GUEST;
  }

  get isCompanyUser() {
    if (!this.user) return false;

    return Object.hasOwn(this.user, "DiscountType") && Object.hasOwn(this.user, "DiscountValue");
  }

  get isOfficialInstitutionUser() {
    if (!this.user) return false;

    return Object.hasOwn(this.user, "EntranceType") && Object.hasOwn(this.user, "Amount");
  }

  get isMembershipUser() {
    if (!this.user) return false;

    return Object.hasOwn(this.user, "HaveMembership");
  }

  login(body: unknown): Observable<any> {
    return this.post<LoginResponse>({ APIName: "login", body }).pipe(
      map((res) => {
        localStorage.setItem(environment.tokenPropertyName, res.token);
        localStorage.setItem("userData", JSON.stringify(res));

        this.toastrService.success(this.translation.instant(`BE_VALIDATION.LOGIN_SUCCESS`, { userName: this.convertTokenJWT()?.NameEn }));
        return res;
      }),
    );
  }

  logOut() {
    localStorage.clear();
  }

  loginAsGuest() {
    return this.get<LoginResponse>({ APIName: "login-as-guest" }).pipe(
      map((res) => {
        localStorage.setItem(environment.tokenPropertyName, res.token);
        localStorage.setItem("userData", JSON.stringify(res));

        return res;
      }),
    );
  }

  register(body: Record<string, unknown>) {
    return this.post<Record<string, unknown>>({ APIName: "register", body, showAlert: true });
  }

  confirmOtp(body: Record<string, unknown>) {
    return this.post({ APIName: "confirm-otp", body, showAlert: true });
  }

  verifyEmail(body: Record<string, unknown>) {
    return this.post({ APIName: "verify-email", body, showAlert: true });
  }

  resetPassword(body: Record<string, unknown>) {
    return this.post({ APIName: "reset-password", body, showAlert: true });
  }

  completeResetPasswordToken(body: Record<string, unknown>) {
    return this.post({ APIName: "complete-reset-password-token", body, showAlert: true });
  }

  completeResetPasswordOtp(body: Record<string, unknown>) {
    return this.post({ APIName: "complete-reset-password-otp", body, showAlert: true });
  }

  resendMobileOtp(body: Record<string, unknown>) {
    return this.post({ APIName: "resend-mobile-otp", body, showAlert: true });
  }

  // private usersService = inject(UsersService);

  // userPermissions: BehaviorSubject<any[] | any> = new BehaviorSubject(null);

  // get userPermissions$(): Observable<any[]> {
  //   if (this.userPermissions.value) {
  //     return this.userPermissions.asObservable();
  //   }

  //   return this.usersService.getUserPermissions().pipe(map(value => {
  //     this.userPermissions.next(value);
  //     return value;
  //   }));
  // }
}
