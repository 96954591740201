import { Routes } from "@angular/router";

export const learningCenterManagementRoutes: Routes = [
  {
    path: "",
    title: "LEARNING_CENTER.TITLE",
    data: {
      tabs: [
        {
          key: "booking",
          title: "DASHBOARD.TABLES",
          routerLink: "tables",
        },
        {
          key: "approvedRequestes",
          title: "DASHBOARD.CHARTS",
          routerLink: "charts",
        },
      ],
    },
    loadComponent: () =>
      import("src/app/shared/base-components/base-tabs-management/base-tabs-management.component").then(
        (c) => c.BaseTabsManagementComponent,
      ),

    children: [
      {
        path: "",
        redirectTo: "tables",
        pathMatch: "full",
      },
      {
        path: "tables",
        loadComponent: () =>
          import("./pages/tables/tables.component").then((c) => c.TablesComponent),
      },
      {
        path: "charts",
        loadComponent: () =>
          import("./pages/charts/charts.component").then((c) => c.ChartsComponent),
      }
    ]
  }
];
