import { Injectable } from "@angular/core";

import {
  GetPagedBody,
  GetPagedResponse,
  HttpService,
  HttpServiceBaseCRUD,
  Lookup,
  SchoolUniversityVisitsVisitorCategory,
  VisitorCategory,
} from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class VisitorCategoriesService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "visitorcategories/";
  }

  get freeSubCategories() {
    return ["retired", "students", "seniorCitizen", "specialNeedsPeople", "socialSecurity", "licensedTourGuide", "icomOrICOMOSMember"];
  }

  get reservationVisitorCategories(): VisitorCategory {
    return {
      Omani: {
        adults: 0,
        children: 0,
        students: 0,
        retired: 0,
        seniorCitizen: 0,
        socialSecurity: 0,
        specialNeedsPeople: 0,
      },
      GCCCitizens: {
        adults: 0,
        children: 0,
        students: 0,
        seniorCitizen: 0,
        specialNeedsPeople: 0,
      },
      Resident: {
        adults: 0,
        children: 0,
        students: 0,
        retired: 0,
        seniorResident: 0,
        specialNeedsPeople: 0,
      },
      Tourist: {
        adults: 0,
        children: 0,
        students: 0,
        retired: 0,
        seniorCitizen: 0,
        specialNeedsPeople: 0,
      },
    };
  }

  get reservationEntranceTicketVisitorCategories() {
    return {
      Omani: {
        adults: 0,
        children: 0,
        students: 0,
        retired: 0,
        seniorCitizen: 0,
        socialSecurity: 0,
        specialNeedsPeople: 0,
      },
      GCCCitizens: {
        adults: 0,
        children: 0,
        students: 0,
        seniorCitizen: 0,
        specialNeedsPeople: 0,
      },
      Resident: {
        adults: 0,
        children: 0,
        students: 0,
        seniorResident: 0,
        specialNeedsPeople: 0,
      },
      Tourist: {
        adults: 0,
        children: 0,
        students: 0,
        specialNeedsPeople: 0,
      },
    };
  }

  get freeReservationVisitorCategories(): VisitorCategory<true> {
    return {
      Omani: {
        students: 0,
        retired: 0,
        seniorCitizen: 0,
        children: 0,
        socialSecurity: 0,
        specialNeedsPeople: 0,
        guestsOfTheDiwan: 0,
        guestsOfRoyalCourt: 0,
        icomOrICOMOSMember: 0,
        licensedTourGuide: 0,
        museumFriends: 0,
        receptionStaffInLicensedHotels: 0,
        sultanatesGuestsAndExcellencies: 0,
        omaniMuseumsEmployees: 0,
        journalistDigitalMedia: 0,
      },
      GCCCitizens: {
        children: 0,
        students: 0,
        seniorCitizen: 0,
        specialNeedsPeople: 0,
        icomOrICOMOSMember: 0,
        licensedTourGuide: 0,
        museumFriends: 0,
        guestsOfTheDiwan: 0,
        guestsOfRoyalCourt: 0,
        receptionStaffInLicensedHotels: 0,
        sultanatesGuestsAndExcellencies: 0,
        omaniMuseumsEmployees: 0,
        journalistDigitalMedia: 0,
      },
      Resident: {
        children: 0,
        students: 0,
        seniorResident: 0,
        specialNeedsPeople: 0,
        icomOrICOMOSMember: 0,
        licensedTourGuide: 0,
        museumFriends: 0,
        guestsOfTheDiwan: 0,
        guestsOfRoyalCourt: 0,
        receptionStaffInLicensedHotels: 0,
        sultanatesGuestsAndExcellencies: 0,
        journalistDigitalMedia: 0,
      },
      Tourist: {
        children: 0,
        students: 0,
        specialNeedsPeople: 0,
        journalistDigitalMedia: 0,
        guestsOfRoyalCourt: 0,
      },
    };
  }

  get schoolUniversityVisitsVisitorCategories(): SchoolUniversityVisitsVisitorCategory {
    return {
      Omani: {
        students: 0,
      },
    };
  }

  getAll() {
    return this.get<Lookup[]>({ APIName: "getall" });
  }

  add(body: Lookup) {
    return this.post<Lookup>({ APIName: "add", body, showAlert: true });
  }

  update(body: Lookup) {
    return this.put<Lookup>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<Lookup>({ APIName: `get-id/${id}` });
  }

  getPaged(body: GetPagedBody<Lookup>) {
    return this.post<GetPagedResponse<Lookup[]>>({ APIName: "getpaged", body });
  }
}
