import { Observable, tap } from "rxjs";
import { startOfMonth } from "date-fns";
import { Injectable, signal } from "@angular/core";

import { EventCalendar } from "../../pages/events-calender";

import { Event, EventInvitation, EventMessage, EventPayload } from "src/app/features/admin/pages/exhibitions-and-events";
import { HttpService, HttpServiceBaseCRUD, GetPagedBody, GetPagedResponse } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class EventsService extends HttpService implements HttpServiceBaseCRUD {
  protected override get baseUrl(): string {
    return "events/";
  }

  monthChanged = signal(startOfMonth(new Date()));

  add(body: EventPayload) {
    return this.post<Event>({ APIName: "add", body, showAlert: true });
  }

  update(body: EventPayload) {
    return this.put<Event>({ APIName: "update", body, showAlert: true });
  }

  remove(id: string) {
    return this.delete<boolean>({ APIName: `deletesoft/${id}`, showAlert: true });
  }

  getById(id: string) {
    return this.get<Event>({ APIName: `get/${id}` });
  }

  getAll() {
    return this.get<Event[]>({ APIName: "getall" });
  }

  getPaged(body: GetPagedBody<Partial<EventPayload>>) {
    return this.post<GetPagedResponse<Event[]>>({ APIName: "get-paged", body });
  }

  sendEventInvitations(body: EventInvitation) {
    return this.post<string>({ APIName: "send-event-invitations", body });
  }

  sendEventMessage(body: EventMessage) {
    return this.post<string>({ APIName: "send-targeted-message", body }).pipe(
      tap(() => this.toastrService.success(this.translation.instant("BE_VALIDATION.MessageSentSuccessfully"))),
    );
  }

  getUpcomingEvents<T>(body: GetPagedBody<T>): Observable<GetPagedResponse<Event[]>> {
    return this.post<GetPagedResponse<Event[]>>({ APIName: "get-paged", body });
  }

  getEventsCalendar(currentDate: string) {
    return this.get<EventCalendar[]>({ APIName: `event-calendar/${currentDate}` });
  }

  getDetails(id: any) {
    return this.get<Event>({ APIName: `get-details/${id}` });
  }

  getUserEventInvitations() {
    return this.get<any[]>({ APIName: "user-event-invitations" });
  }

  // event-invitation-status/324/
  eventInvitationStatus(id: string, statusId: number) {
    return this.put<any>({ APIName: `event-invitation-status/${id}/${statusId}` });
  }
}
