import { Injectable } from "@angular/core";
import saveAs from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"; // For table formatting in jsPDF

const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class ExportService {
  excel<T extends {}>(json: T[], excelFileName: string): void {
    const worksheet = XLSX.utils.json_to_sheet(json);
    // Calculate column widths based on content
    const columnWidths = this.calculateColumnWidths(json);
    worksheet["!cols"] = columnWidths.map((width) => ({ width }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "exported-data");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    this.saveAs(excelBuffer, `${excelFileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`, EXCEL_TYPE);
  }

  pdf(data: any, fileName: string): void {
    this.saveAs(data, `${fileName}.pdf`, "application/pdf");
  }

  /**
   * @param json `Record<string, unknown>[]`
   * @param pdfFileName `string`
   * @description Generates a PDF from the exported data
   */
  generatePdfFromData<T extends {}>(json: T[], pdfFileName: string, isEnglish: boolean , title?: string): void {
    const doc = new jsPDF({
      orientation: "landscape",
      hotfixes: ["px_scaling"],
    });

    // Step 1: Extract headers and rows
    const headers = Object.keys(json[0] || {});
    const rows = json.map((row) => {
      const rowValues = headers.map((header) => {
        const value = (row as Record<string, any>)[header];
        // Handle null, undefined, and other types
        return value !== null && value !== undefined ? String(value) : "";
      });

      return isEnglish ? rowValues : rowValues.reverse();
    });

    // Add Arabic font support
    if (!isEnglish) {
      doc.addFont("./assets/fonts/Amiri-Bold.ttf", "Amiri", "normal", "Identity-H");
      doc.setFont("Amiri");
      // reverse headers
      headers.reverse();
    }
    
    if (title) {
      doc.text(title, doc.internal.pageSize.width / 2, 10, {
        align: "center",
      });
    }


    // Step 2: Configure auto-table
    autoTable(doc, {
      startY: 20,
      head: [headers],
      body: rows,
      theme: "striped",
      styles: {
        font: isEnglish ? "helvetica" : "Amiri",
        fontSize: headers.length > 8 ? 6 : 8,
        cellPadding: 2,
        halign: isEnglish ? "left" : "right",
      },
      headStyles: {
        fillColor: [184, 89, 33],
        font: isEnglish ? "helvetica" : "Amiri",
        textColor: [255, 255, 255],
        halign: isEnglish ? "left" : "right",
        overflow: "linebreak",
        minCellWidth: 15,
      },

      columnStyles: {
        0: { cellWidth: "auto" },
      },
      tableWidth: "auto",
      margin: { left: 10, right: 10 },
    });

    doc.save(`${pdfFileName}.pdf`);
  }

  /**
   * @param data `Record<string, unknown>[]`
   * @returns `number[]`
   * @description Calculate column widths based on content
   */
  private calculateColumnWidths<T extends Record<string, unknown>>(data: T[]): number[] {
    let columnWidths: number[] = [];

    data.forEach((row) => {
      Object.keys(row).forEach((key, index) => {
        const columnLength = Math.max(String(row[key]).length, key.length);

        columnWidths[index] = Math.max(columnWidths[index] || 0, columnLength * 1.2); // Adjust the multiplier for a better fit
      });
    });

    return columnWidths;
  }

  /**
   *
   * @param buffer `BlobPart`
   * @param fileName `string`
   * @param type `string`
   * @description Save the file as a blob
   */
  private saveAs(buffer: BlobPart, fileName: string, type: string): void {
    const data: Blob = new Blob([buffer], { type });
    // let url = window.URL.createObjectURL(data);
    saveAs(data, fileName);
  }
}
