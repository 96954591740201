<p-table
  #table
  [columns]="cols"
  [value]="data"
  [sortField]="'id'"
  [sortOrder]="1"
  [lazy]="true"
  [showCurrentPageReport]="false"
  [paginator]="data.length > 0"
  [rowsPerPageOptions]="[5, 10, 15, 20]"
  [rows]="bodyOptions?.pageSize || data.length"
  [pageLinks]="3"
  [totalRecords]="totalCount"
  [autoLayout]="true"
  [customSort]="true"
  (onLazyLoad)="loadLazyLoadedData($event)"
  styleClass="p-datatable-generic"
  (window:resize)="initializeInputs()"
  [ngClass]="{'d-none': (data && data.length == 0)}"
>
  <ng-template pTemplate="header" let-columns>
    <!-- Table Headers-->
    <tr class="table-header">
      @for (column of columns; track $index) {
        <th
          [pSortableColumnDisabled]="!column.sort"
          [pSortableColumn]="column.sortCol || column.field"
          scope="col"
          [style.min-width]="column?.width ?? 'auto'"
        >
          {{ column.header | translate }}

          @if (column.sort) {
            <p-sortIcon [field]="column.field"></p-sortIcon>
          }
        </th>
      }

      <!-- Show Action In Header Row-->
      @if (actions?.length) {
        <th class="actions" scope="col" style="width: auto">{{ "ACTIONS.TITLE" | translate }}</th>
      }
    </tr>

    <!-- Filter Controls-->
    @if (isFilterEnabled) {
      <tr class="table-search-controls">
        @for (column of columns; track $index) {
          <th class="control" scope="col" [style.min-width]="column?.width ?? 'auto'">
            @switch (column.filter) {
              @case (true) {
                @if (column.filterMode === "text") {
                  <div class="form-group has-search" style="max-width: 140px">
                    <input
                      class="form-control search-box"
                      type="text"
                      (input)="filter($event.target, column.field, column.filterColumnName, column.dataType)"
                      placeholder="{{ column.header | translate }}"
                    />
                  </div>
                }
              }
            }
          </th>
        }

        @if (actions?.length) {
          <th class="actions" scope="col"></th>
        }
      </tr>
    }
  </ng-template>

  <ng-template pTemplate="body" let-row let-columns="columns">
    <!-- Table Rows-->
    <tr class="table-body">
      <!-- Display Data-->
      @for (column of columns; track $index) {
        <td [style.min-width]="column?.width ?? 'auto'">
          @if (column.customCellFormatter) {
            @if (column.customCellClass) {
              <span class="font-13 {{ column.customCellClass(row) }}">
                {{ column.customCellFormatter(row) }}
              </span>
            } @else {
              <span class="font-13">
                {{ column.customCellFormatter(row) }}
              </span>
            }
          } @else {
            @switch (column.filterMode) {
              @case ("date") {
                <span class="font-13" dir="ltr">
                  @if (getCellData(row, column.field) !== "-") {
                    {{ getCellData(row, column.field) | date: (column.dateFormat ? column.dateFormat : "dd/MM/YYYY") }}
                  } @else {
                    {{ "-" }}
                  }
                </span>
              }

              @case ("image") {
                @if (column.field) {
                  <span class="font-13 p-1">
                    <img
                      [src]="getCellData(row, column.field)"
                      [alt]="column.field + ' Image'"
                      width="50"
                      height="50"
                      style="border-radius: 25px"
                      onerror="this.src = ''"
                    />
                  </span>
                } @else {
                  {{ "-" }}
                }
              }

              @default {
                <span class="font-13 {{ column.customCellClass && column.customCellClass(row) }}">
                  {{ getCellData(row, column.field) }}
                </span>
              }
            }
          }
        </td>
      }

      @if (actions?.length) {
        <td class="actions">
          <span class="d-flex align-items-center gap-2 font-14">
            @for (action of actions; track $index) {
              @if (action.icon) {
                <!-- && permissions.indexOf('Permission.'+ componentName+ '.Edit') > -1 -->
                @if ((action.isEdit || action.isView) && (!action.displayBasedOn || action.displayBasedOn(row))) {
                  <span>
                    <a [routerLink]="getFinalRoute(action.route, row.id)">
                      <em class="{{ action.icon }} icon {{ action.color }}" [title]="'ACTIONS.' + action.name | translate"></em>
                    </a>
                  </span>
                }

                <!-- && permissions.indexOf('Permission.'+ componentName + '.Delete') > -1 -->
                @if (action.isDelete && (!action.displayBasedOn || action.displayBasedOn(row))) {
                  <span>
                    <em
                      class="{{ action.icon }} icon {{ action.color }}"
                      (click)="openDeleteModal(row.id)"
                      [title]="'ACTIONS.' + action.name | translate"
                    ></em>
                  </span>
                }

                @if (!action.isEdit && !action.isView && !action.isDelete && (!action.displayBasedOn || action.displayBasedOn(row))) {
                  <span>
                    <em
                      class="{{ action.icon }} icon {{ action.color }}"
                      (click)="action?.call(row)"
                      [title]="'ACTIONS.' + action.name | translate"
                    ></em>
                  </span>
                }

                @if (
                  action.isCheckBox &&
                  !action.isEdit &&
                  !action.isView &&
                  !action.isDelete &&
                  (!action.displayBasedOn || action.displayBasedOn(row))
                ) {
                  <mat-checkbox (change)="action?.call(row)"> </mat-checkbox>
                }
              } @else if (!action.icon && action.call && (!action.displayBasedOn || action.displayBasedOn(row))) {
                <app-button [btnLabel]="action.name" [btnClass]="action.color" (onClick)="action.call(row)" />
              }
            }
          </span>
        </td>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="sorticon" field="col.field" let-sortOrder>
    @if (sortOrder === 0 || sortOrder === 1 || sortOrder === -1) {
      <em class="db-icon-filter font-16 text-white"></em>
    }
  </ng-template>
</p-table>
@if (data && data.length == 0) {
  <div class="d-flex align-items-center justify-content-center h-100">
    <h1>{{'EMPTY.NO_DATA' | translate }}</h1>
  </div>
}