import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { Router, RouterLink } from "@angular/router";
import { Component, HostListener, Input, ViewEncapsulation, inject } from "@angular/core";

import { UserRole } from "../../enums";
import { MenuItem } from "../../interfaces";
import { AuthService, NotificationsService, SidebarToggleService, TranslationService } from "../../services";

import { ButtonComponent, IsEnglishDirective, MenuComponent } from "src/app/shared";

@Component({
  selector: "app-navbar",
  standalone: true,
  imports: [RouterLink, MatToolbarModule, MatListModule, TranslateModule, IsEnglishDirective, ButtonComponent, MenuComponent],
  templateUrl: "./navbar.component.html",
  styleUrl: "./navbar.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
  private router = inject(Router);
  private authService = inject(AuthService);
  private sidebarService = inject(SidebarToggleService);
  private translateService = inject(TranslationService);
  private notificationsService = inject(NotificationsService);

  isEnglish = true;
  user = this.authService.convertTokenJWT();
  isLargeScreen = innerWidth >= 992;
  isMobileScreen = innerWidth <= 580;
  userMenuItems: MenuItem[] = [
    {
      label: "MY_PROFILE.TITLE",
      routerLinkName: "profile",
      menuItemRouterLink: "/profile",
    },
    {
      label: "AUTH.LOGOUT",
      menuItemClick: () => this.logOut(),
    },
  ];

  get notifications() {
    return this.notificationsService.notifications;
  }

  get isGuest() {
    return this.user?.RoleCode === UserRole.GUEST;
  }

  @Input() isOverview = false;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.notificationsService.connect();
    // this.notificationsService.getCount();
    // this.notificationsService.getAll();
  }

  sidebarToggle() {
    this.sidebarService.toggle();
  }

  changeLanguage() {
    this.translateService.changeLanguage();
  }

  setIsReadAndOpenModal(notification: any): void {
    // this.Dialog.open(NotificationModalComponent, {
    //   data: notification,
    //   panelClass: ['medium']
    // });

    this.setIsRead(notification.id);
  }

  setIsRead(notificationId: string): void {
    // this.NotificationService.setIsRead(notificationId).subscribe((res: any) => {
    //   if (res.status === 200) {
    //     this.NotificationService.getAll();
    //   }
    // });
  }

  logOut() {
    this.authService.logOut();
    this.router.navigate([`${this.isGuest ? "/auth" : "/"}`]);
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event: Event) {
    this.isLargeScreen = (event.target as Window).innerWidth >= 992;
    this.isMobileScreen = (event.target as Window).innerWidth <= 580;
  }
}
