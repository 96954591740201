import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { AuthService, Tab, UserRole } from "src/app/core";

export const reportResolver: ResolveFn<Tab[]> = (route, state) => {
  const userRole = inject(AuthService).userRole;
  const statisticalReport = {
    key: "STATISTICALREPORT",
    title: "REPORTS.STATISTICAL_REPORT",
    routerLink: "statistical",
  };

  const financialReport = {
    key: "FINANCIALREPORT",
    title: "REPORTS.FINANCIAL_REPORT",
    routerLink: "financial",
  };

  const evaluationForm = {
    key: "EVALUATIONFORM",
    title: "FRIENDS_OF_OMAN_MUSEUM.EVALUATION_FORM",
    routerLink: "evaluation-form",
  };

  if (userRole === UserRole.STATISTICSDEPARTMENT) {
    return [statisticalReport];
  } else if (userRole === UserRole.FINANCEDEPARTMENT) {
    return [financialReport];
  }

  return [statisticalReport, financialReport, evaluationForm];
};
